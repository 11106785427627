import React, { useEffect, useState } from "react";
import { StatusBar } from "expo-status-bar";
import { Text, View, Image, ImageBackground, SafeAreaView, TouchableOpacity } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import AsyncStorage, { useAsyncStorage } from '@react-native-async-storage/async-storage';

import "./styles";
import Keyboard from "./components/Keyboard";
import { rule } from "postcss";

export default function App() {

  const poss = [
    ["7302022", "woLf adVEntuReR heaVy oVerCoaT winTEr", "https://mj-gallery.com/f6493231-59c7-44f5-881f-e572f219e9b3/grid_0.png"],
    ["7312022", "cOOkie monSTer breAk danCIng nyc", "https://mj-gallery.com/7b26661a-326b-4a64-b6c3-3dff56e34b68/grid_0.png"],
    ["812022", "coRn cob medIEval armOR tinY", "https://mj-gallery.com/d131963d-7764-41f1-bb71-1c0ccdc6e2f3/grid_0.png"],
    ["822022", "alIEn anaToMy draWIng parchMenT colOr", "https://mj-gallery.com/91549e2d-baf2-407d-9f05-8694976f8392/grid_0.png" ],
    ["832022", "thaNOs plaYIng elecTRic guitaR meTal", "https://mj-gallery.com/8866069b-c636-4a20-bb2b-4e0a322c41eb/grid_0.png"]
  ];

  let inputString = "";
  let imageUrl = "";
  let puzzleNumber = -1;

  const dateString = "" + new Date().getMonth() + new Date().getDate() + new Date().getFullYear();

  for(let i=0; i < poss.length; i++) {
    if(poss[i][0] === dateString) {
      inputString = poss[i][1];
      imageUrl = poss[i][2];
      puzzleNumber = i + 1;
    }
  }

  if(puzzleNumber === -1) {
    console.error("NO GAME EXISTS FOR THE DATE: " + dateString);
  }

  //Parse our input string into something we can understand.
  let validAnswers = [];
  let hints = [];
  let splitTerms = inputString.split(" ");

  for(let i=0; i < splitTerms.length; i++) {
    validAnswers.push(splitTerms[i].toUpperCase());
    let thisHint = "";
    for(let j=0; j < splitTerms[i].length; j++) {
      if(splitTerms[i][j].toUpperCase() === splitTerms[i][j]) {
        //Uppercase terms are hints.
        thisHint += splitTerms[i][j];
      } else {
        thisHint += "◽️";
      }
    }
    hints.push(thisHint);
  }

  const [currentGuess, setCurrentGuess] = useState("");
  const [previousGuesses, setPreviousGuesses] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [showHints, setShowHints] = useState(false);
  const [didUseHints, setDidUseHints] = useState("false");
  const [numCorrect, setNumCorrect] = useState(0);
  const [numStrikes, setNumStrikes] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);
  const storageKey = "@igia-guesses-" + dateString;
  const {getItem, setItem} = useAsyncStorage(storageKey);

  //Look for anything we've done for the previous day already.

  const fetchExistingDayData = async () => {
    const existingData = await getItem();
    if(existingData !== null) {
      setPreviousGuesses(JSON.parse(existingData));
      updateGameState(JSON.parse(existingData));
    }
  }

  const fetchExistingHintData = async() => {
    const existingData = await AsyncStorage.getItem("@igia-hints-" + dateString);
    if(existingData !== null) {
      setDidUseHints(existingData);
    }
  }

  useEffect(() => {
    fetchExistingDayData();
    fetchExistingHintData();
    if(imageUrl === "") {
      alert("There was an error loading the game for today's date. Please check back later!")
    }
  }, []);

  const updateCurrentGuess = (newLetter) => {
    if(showGameOver) return;
    setCurrentGuess(currentGuess + newLetter);
  }

  const deleteLetterFromCurrentGuess = () => {
    if(currentGuess.length > 0) {
      setCurrentGuess(currentGuess.slice(0, currentGuess.length-1));
    }
  }

  const updateGameState = (allGuesses) => {

    let totalCorrect = 0;
    let totalStrikes = 0;

    for(let j=0; j < allGuesses.length; j++) {
      let validAnswer = false;
      for(let i=0; i < validAnswers.length; i++) {
        if(validAnswers[i] === allGuesses[j]) {
          validAnswer = true;
        }
      }
      if(validAnswer) {
        totalCorrect++;
      } else {
        totalStrikes++;
      }
    }

    setNumCorrect(totalCorrect);
    if(totalCorrect >= 5) {
      setShowGameOver(true);
    }

    setNumStrikes(totalStrikes);
    if(totalStrikes >= 3) {
      setShowGameOver(true);
    }
  }

  const saveCurrentGuess = () => {
    if(showGameOver) return;
    if(currentGuess === "") return;
    setPreviousGuesses([...previousGuesses, currentGuess]);
    setItem(JSON.stringify([...previousGuesses, currentGuess]));
    updateGameState([...previousGuesses, currentGuess]);
    setCurrentGuess("");
  }

  const prevGuessList = previousGuesses.map((v, i) => {

    let colorToUse = "rgb(239,68,68)";
    for(let i=0; i < validAnswers.length; i++) {
      if(validAnswers[i] === v) {
        colorToUse = "rgb(34,197,94)";
      }
    }

    return (
      <View key={i} className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
        <Text className="text-xl font-bold" style={{color: colorToUse}}>{v}</Text>
      </View>
    )
  })

  const hintList = hints.map((v, i) => {
    return (
      <View key={i} className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-end"}}>
        <Text className="text-xl font-bold text-white">{v}</Text>
      </View>
    );
  })

  return (
    <View className="flex-1 items-center justify-center bg-black">
      <ImageBackground source={{uri: imageUrl}} style={{width: "100%", height: "100%", maxWidth: 450, maxHeight: 900}} resizeMode="cover">
        <View className="bg-black/[0.85] p-2 text-center">
          <Text className="text-white text-md font-bold">FiveWord.art #{puzzleNumber}</Text>
          <View style={{position: "absolute", top: 2, left: 10}}>
            <TouchableOpacity onPress={() => setShowHelp(true)}>
              <Ionicons name="md-help-circle" size={24} color="white" />
            </TouchableOpacity>
            
          </View>
          <View style={{position: "absolute", top: 2, right: 10}}>
            <TouchableOpacity onPress={() => {
              setShowHints(!showHints)
              setDidUseHints("true");
              AsyncStorage.setItem("@igia-hints-" + dateString, "true");
            }}>
              <Ionicons name="md-help-buoy-outline" size={24} color="white" />
            </TouchableOpacity>
            
          </View>
        </View>
        {showHints && 
        <View style={{position: "absolute", top: 40, right: 10}} className="text-right">
          {hintList}
        </View>
        }
        <View style={{marginTop: 10, marginLeft: 10}}>
          {prevGuessList}

          {currentGuess.length > 0 &&
            <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
              <Text className="text-xl text-white font-bold">{currentGuess}</Text>
            </View>
          }
          {/* {currentGuess.length == 0 &&
            <View className="bg-black/[0.85] p-2 mb-2">
              <Text className="text-xl text-slate-300 font-italic">Type to guess...</Text>
            </View>
          } */}
          
          
        </View>

        {showGameOver &&

        <View style={{position: "absolute", top: 0, left: 0, width: "100%", minHeight: "100%"}}>

          <View style={{margin: 80, minHeight: 200, backgroundColor: "rgba(0,0,0,0.85)"}} className="text-center p-4 rounded">
            {numStrikes < 3 &&
            <Text className="text-2xl font-bold text-white">You Win!</Text>
            }
            {numStrikes >= 3 &&
            <Text className="text-2xl font-bold text-white">Good Try...</Text>
            }
            <Text className="text-md text-white pt-1">{numCorrect} Correct, {numStrikes} Strikes</Text>
            <TouchableOpacity className="flex flex-row bg-green-600 p-2 rounded ml-5 mt-4" style={{alignSelf: "flex-start"}} onPress={() => {
              let textToShare = "FiveWord.art " + puzzleNumber + " " + numCorrect + "/" + numStrikes + "\n\n";
              let emojiGrid = "";
              let noMisses = true;

              for(let i=0; i < validAnswers.length; i++) {
                let gotGuess = false;
                for(let j=0; j < previousGuesses.length; j++) {
                  if(previousGuesses[j] === validAnswers[i]) {
                    gotGuess = true;
                  }
                }
                if(gotGuess) {
                  emojiGrid += "🟩 ";
                } else {
                  emojiGrid += "🟥 ";
                  noMisses = false;
                }
              }

              if(noMisses) {
                emojiGrid += "⭐️ ";
              }

              if(didUseHints === "false") {
                emojiGrid += "🔥 ";
              }

              textToShare += emojiGrid;
              textToShare += "\n"
              console.log(textToShare);
              const shareObj = {text: textToShare};
              try {
                navigator.share(shareObj);
              } catch(e) {
                console.error(e);
              }
            }}>
              <Ionicons name="md-share-social-outline" size={24} color="white" />
              <Text className="text-lg ml-2 text-white">Share Results</Text>
            </TouchableOpacity>
          </View>
          
        </View>

        }

      </ImageBackground>
      
      <Keyboard onPress={updateCurrentGuess} onDelete={deleteLetterFromCurrentGuess} onSave={saveCurrentGuess} />

      {showHelp && 
        <View style={{position: "absolute", top: 0, left: 0, width: "100%", minHeight: "100%", backgroundColor: "rgba(0,0,0,0.85)"}}>
          <View style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto", paddingBottom: 10}}>
            <View className="text-right pr-2 pt-1">
              <TouchableOpacity onPress={() => setShowHelp(false)}>
                <Ionicons name="md-close-circle" size={24} color="white" />
              </TouchableOpacity>
            </View>
            <View className="text-center">
              <Text className="text-white text-xl font-bold">👋 How to Play</Text>
            </View>
            <View>
              <Text className="text-white text-md pl-6 pr-6 pt-4">
              👉 Every day you'll see an image that was created by AI based on FIVE WORDS. Your job is to guess those five words. Three strikes and you're out!
              </Text>
              <Text className="text-white text-md pl-6 pr-6 pt-3">
                Just type your guess and press Enter to see if it's correct. If you need some help, press the <Ionicons name="md-help-buoy-outline" size={18} color="white" /> button for some hints.
              </Text>
              <Text className="text-white text-md pl-6 pr-6 pt-3">
                Guess five correctly before three wrong guesses and you win! Guess without any mistakes, you get a gold star ⭐️ No hints, you're on fire 🔥
              </Text>
              <Text className="text-white text-md pl-6 pr-6 pt-3 font-bold">
              ℹ️ Example:
              </Text>
              <ImageBackground className="ml-6 mt-2" source={{uri: "https://mj-gallery.com/f71af594-be38-43cd-aa27-c06b90b79e6b/grid_0.png"}} style={{width: 250, height: 450}}>
              <View style={{marginTop: 10, marginLeft: 10}}>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(34,197,94)"}}>CUTE</Text>
                </View>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(34,197,94)"}}>KITTEN</Text>
                </View>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(239,68,68)"}}>SMALL</Text>
                </View>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(34,197,94)"}}>WEARING</Text>
                </View>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(34,197,94)"}}>DRAGON</Text>
                </View>
                <View className="bg-black/[0.85] p-2 mb-2" style={{alignSelf: "flex-start"}}>
                  <Text className="text-md font-bold" style={{color: "rgb(34,197,94)"}}>KIMONO</Text>
                </View>
              </View>
              </ImageBackground>
              <TouchableOpacity className="ml-6 mt-3 bg-slate-500 p-2 flex-1 rounded" style={{alignSelf: "flex-start"}} onPress={() => setShowHelp(false)}>
                <Text className="text-white font-bold">I'm Ready to Play!</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      }

      <StatusBar style="auto" />
    </View>
  );
}
