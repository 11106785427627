import { Text, View, Image, TouchableOpacity } from "react-native";

export default function Keyboard(props) {
    return (
        <TouchableOpacity className="bg-black/[0.85] p-3 rounded m-0.5 text-center"
            onPress={() => {
                props.onPress(props.letter);
            }}>
            <Text className="font-bold text-white">{props.letter}</Text>
        </TouchableOpacity>
    );
}