import React from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import KeyboardKey from "./KeyboardKey";

export default function Keyboard(props) {
    return (
        <View style={{position: "absolute", bottom: 5, left: 0, width: "100%"}}>
            <View className="flex flex-row items-center justify-center">
                <KeyboardKey letter={"Q"} onPress={props.onPress} />
                <KeyboardKey letter={"W"} onPress={props.onPress} />
                <KeyboardKey letter={"E"} onPress={props.onPress} />
                <KeyboardKey letter={"R"} onPress={props.onPress} />
                <KeyboardKey letter={"T"} onPress={props.onPress} />
                <KeyboardKey letter={"Y"} onPress={props.onPress} />
                <KeyboardKey letter={"U"} onPress={props.onPress} />
                <KeyboardKey letter={"I"} onPress={props.onPress} />
                <KeyboardKey letter={"O"} onPress={props.onPress} />
                <KeyboardKey letter={"P"} onPress={props.onPress} />
            </View>
            <View className="flex flex-row items-center justify-center">
                <KeyboardKey letter={"A"} onPress={props.onPress} />
                <KeyboardKey letter={"S"} onPress={props.onPress} />
                <KeyboardKey letter={"D"} onPress={props.onPress} />
                <KeyboardKey letter={"F"} onPress={props.onPress} />
                <KeyboardKey letter={"G"} onPress={props.onPress} />
                <KeyboardKey letter={"H"} onPress={props.onPress} />
                <KeyboardKey letter={"J"} onPress={props.onPress} />
                <KeyboardKey letter={"K"} onPress={props.onPress} />
                <KeyboardKey letter={"L"} onPress={props.onPress} />
            </View>
            <View className="flex flex-row items-center justify-center">
                <KeyboardKey letter={"Enter"} onPress={props.onSave} />
                <KeyboardKey letter={"Z"} onPress={props.onPress} />
                <KeyboardKey letter={"X"} onPress={props.onPress} />
                <KeyboardKey letter={"C"} onPress={props.onPress} />
                <KeyboardKey letter={"V"} onPress={props.onPress} />
                <KeyboardKey letter={"B"} onPress={props.onPress} />
                <KeyboardKey letter={"N"} onPress={props.onPress} />
                <KeyboardKey letter={"M"} onPress={props.onPress} />
                <KeyboardKey letter={"Del"} onPress={props.onDelete} />
            </View>
        </View>
    );
}